import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ConfigProvider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateParameter, getParameters } from '../../reducers/parametersReducer';

import Map from './Map';
import PoweredBy from './PoweredBy';
import { getRtimeVehicles } from '../../actions/livetrack';
import TemperatureSidebar from './TemperatureSidebar/TemperatureSidebar';

const StyledWrapper = styled.div`
width: 100vw;
height: 100vh;
overflow: hidden;
transition: width 0.5s;
&.temperaturesVisible {
    width: calc(100vw - 360px);
}

&.darkMode {
    /* Dark mode styling */
    .leaflet-tile-pane,
    .leaflet-control-attribution {
        /*-webkit-filter: invert(1) grayscale(0);*/ 
        filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%); /* v2 */
        filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7); /* v3 */
        filter: invert(1); /* v4 */
        filter: invert(100%) hue-rotate(320deg) brightness(80%) contrast(90%); /* v1 */
    }
    .modeBtn svg {
        transform: rotate(180deg)
    }
    /* End of Dark mode styling */
}
`;

const TokenMessage = styled.div`
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    color: #FFF;
    background: #000;
    border: 1px solid #FFF;
    border-radius: 8px;
    text-align: center;
    padding: 4px 16px;
    width: 70%;
    max-width: 200px;
    @media screen and (max-width: 600px) {
        bottom: 80px;
    }
    @media screen and (max-width: 320px) {
        bottom: 100px;
    }
`;

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const LiveTrack = () => {

    const parameters = useSelector(getParameters);
    const { mode, enableStatus, displayLabels, showTemperatures } = parameters;
    const dispatch = useDispatch();

    const [showSettings, setShowSettings] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [fetchTimeout, setFetchTimeout] = useState(null);

    const fetchCb = useCallback((data, error) => {
        if(data && ! error) {
            setVehicles(data)
        }
        setFetchTimeout(setTimeout(() => { fetchVehicles(); }, 2000));
    }, []);

    const fetchVehicles = () => {    
        getRtimeVehicles(token, fetchCb);
    };

    useEffect(() => {
        fetchVehicles();
        return () => { if(fetchTimeout) clearTimeout(fetchTimeout);}
    }, []);

    const handleChange = (type, value) => {
        // Set map provider with a delay
        if(type === 'provider') {
            dispatch(updateParameter({provider: null}));
            setTimeout(() => {
                dispatch(updateParameter({provider: value}));
            }, 200);
        } else {
            dispatch(updateParameter({[type]: value}));
        }
        // dispatch(updateParameter({[type]: value}));
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#eeb810',
                    borderRadius: 2,
                    colorBgContainer: '#fff',
                },
            }}
        >
            <StyledWrapper className={`${mode === 'dark' ? 'darkMode' : ''} ${showTemperatures ? 'temperaturesVisible' : ''}`}>
                <Map
                    vehicles={vehicles}
                    token={token}
                    setShowSettings={setShowSettings}
                    showSettings={showSettings}
                    handleChange={handleChange}
                />

                {showTemperatures
                    ? <TemperatureSidebar
                        vehicles={vehicles.filter((v) => v?.data?.interior_temperature_degrees_c)}
                        displayLabels={displayLabels}
                        enableStatus={enableStatus}
                        handleChange={handleChange}
                    />
                    :null
                }

                {token
                    ? null
                    : <TokenMessage>
                        Missing public token
                    </TokenMessage>
                }
                <PoweredBy />
            </StyledWrapper>
        </ConfigProvider>
    )
}

export default LiveTrack;
