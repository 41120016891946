import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ZoomControl } from 'react-leaflet';
import { isMobile } from 'react-device-detect';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { getParameters } from '../../reducers/parametersReducer';
import LeafletMap from '../LeafletMap';
import VehicleMarkers from './VehicleMarkers';
import MapEventHandler from './MapEventHandler';
// import CopyMapPosition from './CopyMapPosition';
import CopyUrlMapBtn from './CopyUrlMapBtn';
// import ModeSelector from './ModeSelector';
// import InactiveSelector from './InactiveSelector';
import SettingsToggle from './SettingsToggle';
import MapSettings from './MapSettings';
import TemperatureToggle from './TemperatureToggle';

const urlParams = new URLSearchParams(window.location.search);
const userZoom = urlParams.get('zoom');
const userLat = urlParams.get('lat');
const userLng = urlParams.get('lng');
// const userProvider = urlParams.get('provider');

// Set map initial center
// We use the following order -> coordinates provided as an url parrameter -> coordinates stored in the session -> default coordinates
// const center = isLatitude(userLat) && isLongitude(userLng) ? { lat: userLat, lng: userLng } : sessionStorage.getItem("lastCenter") ? JSON.parse(sessionStorage.getItem('lastCenter')) : { lat: 56, lng: 10 };
// const center = { lat: 56, lng: 10 };

// Set map initial zoom 
// We use the following order -> zoom provided as an url parrameter -> zoom stored in the session -> default zoom value
//const zoom = userZoom && userZoom <= 20 && userZoom >=4  ? userZoom : parseInt(sessionStorage.getItem("lastZoom")) || 4;
const bounds =  null;

// Set map provider
// const mapProvider = userProvider && allProviders.includes(userProvider) ? userProvider : appCongig.mapProvider;

const MapElement = styled(LeafletMap)`
.leaflet-tooltip {
    padding: 0px 2px 0 !important;
    margin-top: 0 !important;
    line-height: 16px;
    &:before {
        display: none;
    }
}
.copyBtn {
    position: absolute;
    z-index: 404;
    right: 10px;
    background: #fff;
    top: ${isMobile ? '10px' : '80px'};
    border-radius: 3px;
    width: 33px;
    height: 33px;
    line-height: 32px;
    cursor: pointer;
    border: 2px solid rgba(0,0,0,0.2);
}
.modeBtn {
    position: absolute;
    z-index: 404;
    right: 10px;
    background: #fff;
    top: ${isMobile ? '50px' : '120px'};
    border-radius: 3px;
    width: 33px;
    height: 33px;
    line-height: 32px;
    cursor: pointer;
    border: 2px solid rgba(0,0,0,0.2);
    &.isOn {
        background: #DDD;
    }
}

.hideInactiveBtn {
    position: absolute;
    z-index: 404;
    right: 10px;
    background: #fff;
    top: ${isMobile ? '90px' : '160px'};
    border-radius: 3px;
    width: 33px;
    height: 33px;
    line-height: 32px;
    cursor: pointer;
    border: 2px solid rgba(0,0,0,0.2);
}

.clipboardMessage {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 400;
    color: #FFF;
    background: #000;
    border: 1px solid #FFF;
    border-radius: 8px;
    text-align: center;
    padding: 4px 16px;
}
`;

const Map = ({ vehicles, token, setShowSettings, handleChange, showSettings, setShowTemperatures }) => {
    const parameters = useSelector(getParameters);
    const { provider, zoom, center, mode, hideInactive, enableStatus, displayLabels, showTemperatures } = parameters;
    return (
        <MapElement
            provider={provider}
            onLocationFound={(x, y) => console.log(x, y)}
            bounds={bounds}
            center={center}
            zoomControl={false}
            zoom={zoom}
            minZoom={2}
            maxZoom={18}
            zoomSnap={0.1}
            // zoomDelta={1}
            // wheelPxPerZoomLevel={200}
            preferCanvas
            dragging
            touchZoom
            style={{
                height: '100%',
                width: '100%',
                transition: 'width .5s',
                position: 'relative',
            }}
        >

            {
                isMobile ? null : <ZoomControl position={'topright'} />
            }
            <VehicleMarkers vehicles={hideInactive ? vehicles.filter((v) => v.status !== 'parked') : vehicles} displayLabels={displayLabels}/>
            <MapEventHandler
                center={center}
                zoom={zoom}
                bounds={bounds}
                width= {window.innerWidth}
                height={window.innerHeight}
                disableUserCentering={userZoom && userLat && userLng} // If we have these provided as parameters we will not center the map to the current user position
            />
            {/* <CopyMapPosition
                token={token}
                provider={provider || 'google'}
                mode={mode}
                hideInactive={hideInactive}
                enableStatus={enableStatus}
            /> */}
            {/* <ModeSelector toggleMode={() => { setMode( mode === 'dark' ? 'light' : 'dark'); }} mode={mode}/> */}
            {/* <InactiveSelector toggleInactive={() => { setHideInactive(!hideInactive); }} hideInactive={hideInactive}/> */}
            
            <SettingsToggle setShowSettings={setShowSettings} />
            <TemperatureToggle setShowTemperatures={setShowTemperatures} showTemperatures={showTemperatures}/>

            <Drawer
                title="Settings panel"
                placement={'right'}
                closable={true}
                onClose={() => setShowSettings(false)}
                open={showSettings}
                key={'settings-drawer'}
                zIndex={1010}
                footer={<CopyUrlMapBtn
                    token={token}
                    provider={provider || 'google'}
                    mode={mode}
                    hideInactive={hideInactive}
                    enableStatus={enableStatus}
                    displayLabels={displayLabels}
                    showTemperatures={showTemperatures}
                />}
                styles= {{
                    footer: {
                        textAlign: 'center'
                    }
                }}
            >
                <MapSettings
                    mode={mode}
                    hideInactive={hideInactive}
                    displayLabels={displayLabels}
                    handleChange={handleChange}
                    showTemperatures={showTemperatures}
                    enableStatus={enableStatus}
                />
                
            </Drawer>

        </MapElement>
    )
};

Map.propTypes = {
    vehicles: PropTypes.array,
    token: PropTypes.string,
    mode: PropTypes.string,
    setMode: PropTypes.func,
};

export default Map;
