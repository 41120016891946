import { api } from './core.js';

const livetrackApi =  {  
    getRtimeVehicles: (token) => new Promise((resolve, reject) => {
      const url = `https://www.obiplus.net/rtime/api/v2/private-channel?token=${token}`
      api.GET(url)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    }),
};

export default livetrackApi;