import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../../Icon/Icon';
import TemperatureData from './TemperatureData';

const VehicleWrapper = styled.div`
    padding: 8px 16px;
    margin-bottom: 8px;
    border-bottom: 1px solid #ddd;
    .vehicleInfo {
        display: flex;  
        column-gap: 8px;   
        margin-bottom: 16px;   
    }
    .vehicleName {
        flex: 1;
        font-size: 12px;
        line-height: 16px;
    }
    .nameRow1 {
        color: #535354;
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        max-width: 210px;
        text-overflow: ellipsis;
    }
    .nameRow2 {
        font-weight: 500;
        color: #929497;
    }
`;

const statusColors = {
    moving: '#00af61',
    parked: '#929497',
    idling: '#929497',
}

const SingleVehicle = ({ vehicle, displayLabels, enableStatus }) => {
    const { data, registration, display_name, status, type } = vehicle;
    const row1 = displayLabels === 'name' ? display_name || registration?.id || null : registration?.id || display_name || null;
    const row2 = displayLabels === 'name' ? registration?.id || display_name || null : display_name || registration?.id || null;
    return (
        <VehicleWrapper>
            <div className={'vehicleInfo'}>
                <div className={'vehicleName'}>
                    <div className={'nameRow1'}>{row1}</div>
                    <div className={'nameRow2'}>{row1 !== row2 ? row2 : ''}</div>
                </div>
                <div className={'vehicleType'}>
                    <Icon iconName={`vehicle-type-list-icon-${type || 'car'}`}
                        color={'#535354'}
                        scale={6}
                    />
                </div>
                <div className={'vehicleStatus'}>
                    {enableStatus
                        ? <Icon
                            iconName={`status-${status}`}
                            scale={3}
                            color={statusColors[status]}
                        />
                        : null
                    }
                </div>
            </div>

            {data?.interior_temperature_degrees_c?.value?.length
                ? <TemperatureData vehicle={vehicle} />
                : null
            }
        </VehicleWrapper>
    )
};

SingleVehicle.propTypes = {
    vehicle: PropTypes.object,
};

export default SingleVehicle;