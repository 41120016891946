import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    position: absolute;
    z-index: 1000;
    bottom: 0px;
    left: 0px;
    align-items: center;
    width: calc(99% - 16px);
    display: flex;

    svg {
        width: 12%;
        min-width: 170px;
    }
    .logoWrap {
        a {
            display: block;
            margin: 0;
            padding: 24px;
            line-height: 0;
            background-color: #f8d35b;
            border-radius: 0 16px 0 0;
        }
    }
    
    @media screen and (max-width: 600px) {
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        align-items: center;
        flex-direction: column;
        svg {  
            margin: 0 auto;    
        }
        .logoWrap a {
            border-radius: 0;
            padding: 16px;
        }
    }

    @media screen and (max-width: 320px) {
        left: 0;
        transform: translateX(0);
        display: block;
        .logoWrap {
            width: 100%;
            a {
                width: 100%;
                padding: 8px;
            }
        }
        svg {
            font-size: 10px;   
            margin: 0 auto;    
        }
    }
`;

const PoweredBy = () => {
  return (
    <StyledWrapper>        
        <div className={'logoWrap'}>
            <a href="https://traffitech.com" target="_blank" name="Traffitech" rel="noreferrer">
                <svg
                    x="0px" y="0px"
                    viewBox="0 0 588.06 81.56"
                    height="1.5em">
                    <polygon fill="#231f20" points="147.17 0 107.02 0 107.02 26.76 147.13 26.76 160.53 13.38 147.17 0"/>
                    <path fill="#231f20" d="M0,26.76V0h26.76l53.51,53.51v26.76h-26.76v-40.16c0-7.38-5.98-13.36-13.36-13.36H0Z"/>
                    <path fill="#231f20" d="M53.51,26.76V0h26.76l53.51,53.51v26.76h-26.76v-40.16c0-7.38-5.98-13.36-13.36-13.36h-40.16Z"/>
                    <path fill="#231f20" d="M224.41,9v8.79h-20.67v62.48h-9.19V17.79h-20.57v-8.79h50.43Z"/>
                    <path fill="#231f20" d="M231.07,38.72c2.9-6.19,8.09-9.29,15.58-9.29v9.09c-4.29-.2-7.89.9-10.98,3.39-3.1,2.5-4.59,6.39-4.59,11.88v26.46h-8.69V30.33h8.69v8.39Z"/>
                    <path fill="#231f20" d="M293.78,30.33h8.69v49.93h-8.69v-8.59c-4.29,6.59-10.59,9.89-18.87,9.89-6.99,0-12.98-2.5-17.88-7.59s-7.39-11.38-7.39-18.67,2.5-13.48,7.39-18.57c4.89-5.09,10.89-7.69,17.88-7.69,8.29,0,14.58,3.3,18.87,9.89v-8.59ZM288.69,68.08c3.39-3.5,5.09-7.69,5.09-12.78s-1.7-9.29-5.09-12.68c-3.39-3.5-7.59-5.19-12.68-5.19s-9.19,1.7-12.58,5.19c-3.39,3.39-5.09,7.59-5.09,12.68s1.7,9.29,5.09,12.78c3.39,3.39,7.59,5.09,12.58,5.09s9.29-1.7,12.68-5.09Z"/>
                    <path fill="#231f20" d="M433.45,9v8.79h-20.67v62.48h-9.19V17.79h-20.57v-8.79h50.43Z"/>
                    <path fill="#231f20" d="M437.26,59.29c1.7,8.89,8.59,14.08,18.08,14.08,6.59,0,11.38-2.4,14.38-7.29l7.39,4.19c-4.89,7.49-12.18,11.28-21.97,11.28-7.89,0-14.38-2.5-19.37-7.39-4.89-4.99-7.39-11.28-7.39-18.87s2.4-13.78,7.29-18.77c4.89-4.99,11.18-7.49,18.87-7.49,7.29,0,13.28,2.6,17.88,7.79,4.69,5.09,6.99,11.28,6.99,18.57,0,1.3-.1,2.6-.3,3.89h-41.84ZM437.26,51.7h33.35c-1.5-9.49-8.09-14.48-16.08-14.48-9.29,0-15.78,5.69-17.28,14.48Z"/>
                    <path fill="#231f20" d="M493.99,74.07c-4.99-5.09-7.49-11.28-7.49-18.77s2.5-13.68,7.49-18.67c4.99-5.09,11.28-7.59,18.77-7.59,9.89,0,18.17,5.09,22.07,13.08l-7.29,4.19c-2.5-5.29-8.09-8.79-14.78-8.79-4.99,0-9.19,1.7-12.58,5.09-3.29,3.39-4.99,7.59-4.99,12.68s1.7,9.19,4.99,12.58c3.4,3.39,7.59,5.09,12.58,5.09,6.69,0,12.28-3.4,15.08-8.69l7.39,4.29c-4.29,7.89-12.78,12.98-22.47,12.98-7.49,0-13.78-2.5-18.77-7.49Z"/>
                    <path fill="#231f20" d="M588.06,49.6v30.66h-8.69v-30.16c0-8.09-4.69-12.78-12.38-12.78-8.69,0-14.48,5.29-14.48,16.48v26.46h-8.69V9h8.69v28.52c3.5-5.69,8.89-8.49,16.18-8.49,11.68,0,19.37,7.89,19.37,20.57Z"/>
                    <path fill="#231f20" d="M347.12,24.63v5.7h-18.34v-8.16c0-2.42,1.96-4.39,4.39-4.39h13.93s0-8.79,0-8.79h-11.47l-15.64,15.64v5.7h-8.75v8.75h8.76v41.18h8.78v-41.18h18.34v41.18h8.79v-41.18h8.74v-8.75h-8.75v-8.16c0-2.42,1.96-4.39,4.39-4.39h13.93s0-8.79,0-8.79h-11.47l-15.64,15.64Z"/>
                    <rect fill="#231f20" x="374.24" y="30.33" width="8.76" height="49.93"/>
                </svg>
            </a>
        </div>
    </StyledWrapper>
  )
}

export default PoweredBy;
