import API from '../api/livetrack';
import store from '../store';

// Determine vehicle status based on timestamp, speed, direction and location change
const getStatusByIgnition = (vehicle) => {
  if(vehicle.speed) return 'moving'; // We have speed so the vehicle should be moving

  // `vehicle.value` is a boolean representing the ignition state of the vehicle. Looks a bit weird but it was implemented that way because it was not specified intially when we started working on this project
  if(vehicle?.data?.ignition_state?.value) return 'idling'; // Ignition is ON but we do not have speed so the vehicle should be idling

  return 'parked'; // In all other cases we assume the vehicle is parked
};

const getDeviation = (value, target) => {
  if (isNaN(value) || isNaN(target)) return 0;
  if (Math.abs(value - target) >= 5) return 3;
  if (Math.abs(value - target) >= 3) return 2;
  if (Math.abs(value - target) >= 1) return 1;
  return '';
};

const getAlertLevel = (vehicle) => {
  let alertLevel = 0;
  if(vehicle?.target_interior_temperatures?.length && vehicle?.data?.interior_temperature_degrees_c?.value?.length) {
    // We have temperature data so we need to determine if we have any alerts
    vehicle.data.interior_temperature_degrees_c.value.forEach((t) => {
      const setTemp = vehicle.target_interior_temperatures.find((x) => x.position === t.position);
      if(setTemp && !isNaN(setTemp.target)) {
        const level = getDeviation(t.value, setTemp?.target);
        alertLevel = alertLevel < level ? level : alertLevel;
      }
    });
  }

  return alertLevel;
}

export const getRtimeVehicles = (token, cb = null)  => {
  if(!token) {
        if(cb) cb(null, true);
        return;
    }
    API.getRtimeVehicles(token)
      .then((res) => {
        
        if (res.code !== 200) {
            if(cb) cb(null, true);
            return;
        }

        const { parameters } = store.getState();
        const { enableStatus } = parameters;
        if(cb) cb(res.data
            .map((x) => ({...x, ...(x?.data?.location) && x.data.location}))
            .filter((v) => v.lat && v.lng).map((v) => ({...v, status: enableStatus ? getStatusByIgnition(v) : 'moving', temperatureAlert: getAlertLevel(v) })), null);
        
      }).catch((error) => {
        console.log(error);
        if(cb) cb(null, true);
    });
};