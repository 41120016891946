import React, { useEffect } from 'react';
import styled from 'styled-components';
import { appCongig } from '../../config/appConfig';

const ErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1200px;
  height: 100vh;
  margin: 0 auto;

   .title {
     color: #000;
     font-size: 32px;
     font-weight: bold;
     margin-top: 48px;
     margin-bottom: 8px;
     text-align: center;
  }
   .subTitle {
     width: 70%;
     margin: 0 auto 32px;
     color: #000;
     text-align: center;
     font-size: 16px;
   }
   img {
    max-width: 100%;
   }
   button{
     background: #000000;
     color: #EEB810;
     border-color:#000000;
     border-radius: 8px;
     cursor: pointer;
     min-width: 192px;
     padding:8px 0px;
      span {
         font-size: 16px;
         font-weight: bold;
   }
   &:hover{
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
   }
}
 .backHome {
     color: #535354 !important;
     font-size: 12px;
     margin-top: 14px;
     cursor: pointer;
     text-decoration: none;
   }
`;

const handleRefreshClick = async () => {
  // Uninstall all service workers
  navigator.serviceWorker.getRegistrations().then((registrations) => {
	  registrations.forEach((registration) => {
		  registration.unregister();
	  });
  });

  // Clear all the cache
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });

  // Reset automatic refresh retries count
  sessionStorage.removeItem("refreshRetries");

  // Reload the page
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}


// This is the fullpage error component that will be shown if something major happens
// or if there aren't any child error boundaries

export const ErrorPage = () => {
  // Automatically reload the page in case of an error
  // but to a limited amont of retries
  useEffect(() => {
    if(appCongig.refreshOnError && (!sessionStorage.getItem("refreshRetries") || parseInt(sessionStorage.getItem("refreshRetries")) < appCongig.refreshAttempts)) {
      // Increment refresh attempts
      sessionStorage.setItem("refreshRetries", sessionStorage.getItem("refreshRetries") ? parseInt(sessionStorage.getItem("refreshRetries")) + 1 : 1);
      
      // Refresh the page
      setTimeout(() => {
        console.log('Error page loaded');
        window.location.reload();
      }, 500);
    }
  }, []);

  return (
    <ErrorPageWrapper>
      <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="error" />
      <p className='title'>Oops! Something went wrong.</p>
      <p className='subTitle'>We logged the error and are doing our best to fix it.</p>
      <button onClick={() => handleRefreshClick()}><span>Refresh page</span></button>
    </ErrorPageWrapper>
  )
}

export default ErrorPage;