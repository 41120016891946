import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import store from './store'
import { Provider } from 'react-redux'

Sentry.init({
    dsn: 'https://bf747e5636624e7f8d7eb7e0ab057016@o178944.ingest.sentry.io/4504912639098880',
    release: process.env.REACT_APP_VERSION,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
