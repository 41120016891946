export const api = {
  GET: (url) => new Promise((resolve, reject) => {
    fetchRetry(url, { method: 'GET' })
    .then((res) => res.json())
    .then((json) => resolve(json))
    .catch((error) => reject(error));
  }),
};

// This will retry the same request in case any of the below retryCodes are returned to avoid network or any other temporary issues
async function fetchRetry(url, options = {}, retries = 3) {
  const retryCodes = [408, 500, 502, 503, 504, 520, 522, 524]
  try {
    const res = await fetch(url, options);
    if (retries > 0 && retryCodes.includes(res.status)) {
      return delay(3000).then(function() {
        return fetchRetry(url, options, retries - 1);
      });
    } else {
      return res;
    }
  } catch (message) {
    return console.error(message);
  }
}

// Sleep function 
function delay(t, v) {
  return new Promise(function(resolve) { 
      setTimeout(resolve.bind(null, v), t)
  });
}
