import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Empty } from 'antd';
import SingleVehicle from './SingleVehicle';
import Icon from '../../Icon/Icon';

const StyledWrapper = styled.div`
    background: #fff;
    position: absolute;
    z-index: 1001;
    right: 0;
    bottom: 0;
    top: 0;
    width: 360px;
    max-width: 100vw;
    overflow-y: auto;
    .sidebarHeader {
        padding: 8px;
        text-align: center;
        font-size: 14px;
        color: #535354;
        font-weight: bold;
        background: #EEE;
        border-bottom: 2px solid #CCC;
        position: sticky;
        top: 0;
    }
    .ant-empty-image {
        margin-top: 16px;
    }
    .closeBtn {
        position: absolute;
        right: 4px;
        top: 8px; 
        border: none;
        cursor: pointer;
        svg {
            fill: rgba(0, 0, 0, 0.45);
        }
        &:hover {
            svg {
                fill: #535354;
            }
        }
    }
`;

const TemperatureSidebar = ({ vehicles, enableStatus, displayLabels, handleChange }) => {
    return (
        <StyledWrapper>
            <div className={'sidebarHeader'}>
                Temperature data

                <button className={'closeBtn'} onClick={() => handleChange('showTemperatures', false)}>
                    <Icon iconName='cross' scale={2.25} color={'#535354'}/>
                </button>
            </div>
            {vehicles.length
                ? vehicles.sort(((a,b) => (a.temperatureAlert > b.temperatureAlert) ? -1 : ((b.temperatureAlert > a.temperatureAlert) ? 1 : 0))).map((v, i) => <SingleVehicle key={`vehicle-${i}`} vehicle={v} displayLabels={displayLabels} enableStatus={enableStatus}/> )
                : <Empty />
            }
        </StyledWrapper>
    )
};

TemperatureSidebar.propTypes = {
    vehicles: PropTypes.array,
};

export default TemperatureSidebar;