import React, { useEffect } from 'react';
import { ErrorBoundary } from "react-error-boundary";
import styled from 'styled-components';
import './App.css';
import LiveTrack from './components/LiveTrack/LiveTrack';
import ErrorPage from './components/ErrorPage/ErrorPage';

const AppWrapper = styled.div`

`;

const App = () => {
  useEffect(() => {
    // Reset the refresh attempt counter after a minute
    // so we can use the autrefresh functionality again after successfull recovery
    setTimeout(() => {
      sessionStorage.removeItem("refreshRetries");
    }, 60000);
  }, []);

  return (
    <AppWrapper>
      <ErrorBoundary fallback={<ErrorPage />}>
        <LiveTrack />
      </ErrorBoundary>
    </AppWrapper>
  );
}

export default App;
